@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  padding: 0;
}

p, h3, h4, h5, h6, span, button, div, .mdc-drawer .mdc-drawer__title, li.mdc-ripple-upgraded.mdc-list-item {
  font-family: 'Poppins', Arial, sans-serif;
}

.tmenuGreen {
  color: #08AA3E !important;
}

.tmenuGreenBg {
  background: #08AA3E !important;
}

.tmenuRed {
  color: #e20413 !important;
}

.tmenuRedBg {
  background: #e20413 !important;
}

.MainMenu span.mdc-tab__text-label {
  font-size: 0.9em;
}

aside.mdc-drawer--open.mdc-drawer.mdc-drawer--modal i {
  margin-right: 5%;
}

.mdc-tab-scroller__scroll-content a {
  text-transform: capitalize;
}

aside.mdc-drawer--open.mdc-drawer.mdc-drawer--modal {
  top: 0;
  background: #171717 !important;
  height: 100%;
}

.mdc-tab--stacked {
  height: 60px !important;
}

.btnToBottom button {
  width: 100%;
  min-height: 60px;
  text-transform: initial;
  display: block;
  border-radius: 0;
}

.mdc-drawer {
  border-right-width: 0px !important;
}

aside.mdc-drawer--open.mdc-drawer.mdc-drawer--modal div {
  color: white;
}

.btnToBottom {
  margin-bottom: 1px;
}

body .mdc-drawer .mdc-list-item:not(.mdc-list-item--activated) {
  color: white !important;
}

.mdc-drawer .mdc-drawer__title {
  font-weight: 900;
  font-size: 1.4em;
}


.qtyControl {
    display: inline-flex;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    margin-right: 10px;
}

.qtyInput {
  display: inline-block;
  min-width: 35px;
  line-height: 34px;
  text-align: center;
  font-size: 1em;
  font-weight: 600;
  margin: 3px 0;
  align-self: center;
  color: black;
}

.mdc-icon-button::before, .mdc-icon-button::after {
  background-color: #00000038 !important;
}

button.plusMinus {
    float: none !important;
    background: transparent !important;
    color: #e4432f !important;
    text-transform: none !important;
    padding: 0 !important;
    font-weight: 500;
}

.productImage img {
    display: flex;
    border-radius: 12px;
    width: 120px;
    height: 80px;
    object-fit: cover;
}

.produtctDetailsModal {
  padding: 8%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}

.produtctDetailsModal p.productDescription {
  margin-bottom: 10%;
}

.productImageModal img {
  width: 533px;
  max-width: 100%;
  max-height: 400px;
  height: auto;
}

.productImage {
  align-self: center;
  margin-right: 6%;
}

.containerProductCatList h2, p {
  margin: 0;
  padding: 0;
}

.produtctDetailsBtnAdd {
  float: right;
  background: #e20413 !important;
  color: white !important;
  text-transform: capitalize !important;
  padding: 7%;
  width: 100% !important;
  height: 50px !important;
  font-weight: 500;
}

p.productDescription {
  line-height: 18px;
  font-size: 0.75em; 
  color: #5a5a5a;
}

.containerProductCatList p.productDescription {
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
}

.produtctDetails * {
  padding: 2% 0;
}


.containerProductCatList li {
  display: flex;
  margin-bottom: 3%;
  border: 1px solid #eaeaea;
  margin-top: 3%;
  padding: 3%;
  border-radius: 12px;
  min-height: 113px;
}

.modalItemControl {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.produtctDetails button {
  float: right;
  display: flex;
  margin-top: 0%;
  text-align: center;
  color: #E20413;
  justify-content: center;
  font-size: 1.6em !important;
  margin-bottom: -5%;
}

.containerProductCatList h2 {
  color: black;
    font-size: 0.9em;
    font-weight: 500;
}

.containerProductCatList p {
  color:#5a5a5a;
}

.produtctDetailsModal h2.productTitle {
  font-size: 1em;
  font-weight: 500;
}

.containerProductCatList h2.productTitle {
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
}

.produtctDetails {
    min-width: 55%;
    display: grid;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0% 2%;
}


.containerProductCatList {
  margin: 3%;
}

p.productPrice {
  max-width: 30%;
  display: contents;
  color: black;
  font-size: 0.9em;
  font-weight: 500;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

span.mdc-typography--body2 img {
  width: 70px !important;
  height: auto !important;
}

.TabCats button.mdc-ripple-upgraded.mdc-tab.mdc-tab--active{
  font-weight: bold;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.MasterContainer, header.TmenuHeader img {
  max-width: 650px;
}

:root {
  --mdc-theme-primary: #000 !important;
}

nav.MainMenu.mdc-tab-bar {
  max-width: 650px;
}

input.mdc-text-field__input::placeholder {
  font-size: 0.9em;
  font-style: italic;
}

.ModalBuscaHeader .mdc-dialog__actions {
  display: none;
}

.ModalBuscaHeader button.mdc-ripple-upgraded.mdc-button.mdc-button--unelevated {
  width: 100%;
  margin-top: 5%;
}

header.TmenuHeader img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
  height: auto;
    width: 100%;
}

.MasterContainer {
  display: block;
  margin: 0 auto;
  /*background: #f1f1f1;*/
}

.mdc-tab-scroller__scroll-content button {
  text-transform: capitalize;
}

.BarBottomHeader {
  background: black;
  display: flex;
  align-items: center;
  color: white !important;
  text-align: center;
  justify-content: space-between;
  padding: 4% 8%;
}
.BarBottomHeader button {
  font-size: 0.9em;
}

.BarBottomHeader span.mdc-typography--body2 {
    color: #ccc;
    font-size: 0.8em;
}

span.mdc-typography--body2 span strong {
  font-size: 1.1em;
}

.mdc-tab-scroller {
  box-shadow: 1px 1px 5px #0000001f;
}

.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-color: #EC4A52 !important;
}

.BarBottomHeader button {
  color: white !important;
  text-transform: capitalize !important;
}
.mdc-tab--active .mdc-tab__text-label {
  color: #EC4A52 !important;
}

.MainMenu {
  position: fixed;
  bottom: 0;
  display: block;
  background: #000;
}

.MainMenu span.mdc-tab__text-label, .MainMenu i {
  color: white !important;
  font-weight: 200;
}

.MainMenu .mdc-tab-scroller__scroll-area {
  overflow-x: hidden !important;
}

.MainMenu .rmwc-badge--align-end {
    right: -10%;
    top: 20% !important;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

.MainMenu .mdc-tab:not(.mdc-tab--active) .mdc-tab__text-label {
  opacity: 1;
}

.MainMenu button.mdc-ripple-upgraded.mdc-tab.mdc-tab--stacked.mdc-tab--active {
  background: #6b6b6b38;
}

.loading{
width: 20rem;
height: 20rem;
margin-left: auto;
margin-right: auto;
}
.loadingProfile{
  width: 9rem;
  height: 8rem;
  margin-top: 10rem;
  margin-left: auto;
  margin-right: auto;
  }
.containerOrders{
  margin-bottom: 5rem;
}

.containerOrders h1{
  text-align: center;
}

.listOrder{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}

.listItemOrder{
  border: solid #ccc;
  border-radius: 10px;
  height: 4.5rem;
  margin-top: 0.5rem;
  background: #f4f5f3;
  padding: 1rem 0.5rem 1rem 0.5rem;
}
.listRow{
  display: grid;
  grid-template-columns: 1fr 1fr;

}
.listRowBottom{
  display: grid;
  grid-template-columns: 1.4rem 7rem 10rem;
}
.tableInfo{
  font-size: 1.8rem;
  font-weight: bold;
}

.dataInfo{
  font-size: 0.8rem;
  margin-bottom: 0.8rem;
}

.extratoInfo{
  margin-top: 0.8rem;
}

.extratoInfo {
cursor: pointer;
}
.extratoInfo:hover{
  background: #eeebeb;
  margin-right: 0.3rem;
  }

.statusInfoTrue{
  margin-top: 0.5rem;
  background: rgb(10, 231, 47);
  border-radius: 12px;
  border: solid rgb(10, 231, 47);
  text-align: center;
}
.statusInfoFalse{
  margin-top: 0.5rem;
  background: rgb(193, 197, 193);
  border-radius: 10px;
  border: solid rgb(193, 197, 193);
  text-align: center;
}


.badge{
  cursor: pointer;
}

.clientName{
  font-weight: bold;
  color: #000;
}
.titleExtract{
  color: #000;
}
.textRigth{
  font-size: 0.7rem;
  color: #000;
}
.textSub{
  color: #000;
}
.textThrough{
  text-decoration: line-through;
  font-size: 0.7rem;
}

.questionAccount{
  text-align: center;
  color: #000;
}


.charContainer{
  min-width: 20rem;
  min-height: 20rem;
}
.charContainer h1{
  text-align: center;
  font-size: 1.6rem;
}
.charContainer h2{
  text-align: center;
}
.charContainer p{
  text-align: center;
  font-size: 0.8rem;
}
.charContainer h3{
  text-align: center;
}

.containerNoData{
  text-align: center;
}
.buttonChart{
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}
.btnConfirme{
  width: 14rem;
  height: 2rem;
  background: #08AA3E !important;
  color: #FFFFFF !important;
  border: none;
  margin-bottom: 1rem;
}
.btnAccountTable{
  width: 14rem;
  height: 2rem;
  background: #eb3a43;
  color: #FFFFFF;
  border: none;
  border-radius: 5%;
  margin-bottom: 1rem;
}
.btnAdd{
  width: 14rem;
  height: 2rem;
  background: #070707 !important;
  color: #FFFFFF !important;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.btnConfirmAccount{
  width: 5rem;
  height: 2rem; 
  background: #08AA3E !important; 
  color: #FFFFFF !important;
  border: none;
  border-radius: 5%;
  margin: 1rem;
}
.btnNotAccount{
  width: 5rem;
  height: 2rem;
  background: #e20413 !important;
  color: #FFFFFF !important;
  border: none;
  border-radius: 5%;
  margin: 1rem;
}


.btnAddEqual{
  width: 5rem;
  font-size: 0.5rem;
  margin-right: 0.1rem;
  border: solid 1px #0fc24a;
  color: #0e0f0e;
  float: right;
}

.btnAddOther{
  width: 5rem;
  font-size: 0.5rem;
  margin-right: 0.1rem;
  border: solid 1px #0fc24a;
  color: #0e0f0e;
  float: right;
}

.divImage{
  text-align: center;
}
.charContainer img{
  width: 10rem;
  height: 10rem;
  margin-left: auto;
  margin-right: auto;
}
.divImage img{
  width: 10rem;
  height: 10rem;
  margin-left: auto;
  margin-right: auto;
}

.exclamacaoImg{
margin: 1rem;
}

.charContainer div{
  margin-left: 1rem;
}


button.plusMinusChart{
  float: none !important;
  border: solid 1px;
  background: transparent !important;
  color: #0e0d0d !important;
  text-transform: none !important;
  padding: 4px !important;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: bold;
}

.qtyChart {
  display: inline-flex;
}
.qtyInputChart {
  display: inline-block;
  min-width: 20px;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  align-self: center;
  color: black;
}

.loginContainer{
  text-align: center;
  padding-top: 5rem;
}
.loginForm{
  text-align: center;
  min-width: 20rem;
  margin-top: auto;
  margin-left: auto;
}

.signupContainer{
  text-align: center;
  min-width: 20rem;
  min-height: 42rem;
  margin-top: auto;
  margin-left: auto;
  display: grid;
}
.signupForm{
  width: 20rem; 
  margin-left: auto;
  margin-right: auto;
}
.signupBtn{
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 16rem;
  color: #fcf6f6 !important;
  background: #070707 !important;
  cursor: pointer;
}

.signupError{
  margin-top: 1rem;
  color: #d10700;
}
.form{
  display: block;
}

.totalTable{
  text-align: center;
  font-size: 1rem;
  color: #000;
}

.profileContainer{
  margin-bottom: 5rem; 
  padding: 1rem;
}
.editProfileContainer{
  text-align: center;
}

.inputName{
  border: none;
  font-size: 1.5rem;
  text-align: center;
  width: 20rem;
}
.inputData{
  border: none;
  font-size: 1rem;
  text-align: left;
  width: 15rem;
}

.btnGroupProfile{
  display: flex;
  text-align: center;
}
.btnProfileSave{
  font-size: 1.3rem;
  background: #08AA3E !important;
  color: #FFFFFF !important;
  width: 9rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.btnProfileLogout{
  font-size: 1.3rem;
  background: #e20413 !important;
  color: #FFFFFF !important;
  width: 9rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}


.attributeContainer{
  display: grid;
}
.nameCountAtt{
  display: flex;
}

.nameAttribute{
  margin-right: 1rem;
  font-size: 1rem;
}

.countAttribute{
  margin-left: auto;
}
.requiredAttribute{
  margin-left: auto;
  font-size: 0.8rem;
  background: #d6d6d4;
  border: none;
  border-radius: 10%;
  padding: 0.2rem;
}

.descriptionAttribute{
  font-size: 0.8rem;
}
.attValue{
  display: flex;
}

.nameValue{
font-size: 0.8rem;
}
.addValue{
margin-left: auto;
padding: 0rem 0.6rem;
color: #e4121c;
cursor: pointer;
}

.qrcodeHome{
width: 15rem;
height: 15rem;
}


.mainContainer{
  position: relative;
}
.getWaiter{
  width: 18rem;
  background: #08AA3E !important;
  color: #FFFFFF !important;
  margin-left: 2rem;
  margin-right: 5rem;
  margin-bottom: 0;
  position: absolute !important;
  bottom: 4rem !important;
}

.tmenuGreenBtn {
  background: #08AA3E !important;
  width: 17rem;
  color: #FFFFFF !important;
}

.tmenuRedBtn {
  background: #e20413 !important;
  width: 17rem;
  margin-top: 0.5rem; 
  color: #FFFFFF !important;
}

.socialButtons{
  display: grid;
  text-align: center;
}

.btnFace{
  background: #3b5998 !important;
  color: #FFFFFF !important;
  width: 17rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

.btnGoogle{
  width: 17rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  box-shadow: 0px 1px #a7a4a4 !important;

}


.btnGuest{
  width: 17rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  box-shadow: 1px 1px 1px 1px #a7a4a4 !important;
}
.avatarContainer{
  display: flex;
  align-items: center;
  padding-left: 1rem;
}

.avatarContainer img{
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;

}

.avatarContainer p{
  margin-left: 0.5rem;
}