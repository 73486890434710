.btnCallContainer{
    display: grid;
    text-align: center;
}

@media only screen and (max-width: 1800px) {
    .getWaiter{
        width: 90%;
        background: #08AA3E !important;
        color: #FFFFFF !important;
        margin-top: 10rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4rem !important;
        position: sticky !important;
        bottom: 4rem !important;
    }
  }
  
  @media only screen and (max-width: 900px) {

    .getWaiter{
        width: 90%;
        background: #08AA3E !important;
        color: #FFFFFF !important;
        margin-top: 5rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4rem !important;
        position: sticky !important;
        bottom: 4rem !important;
    }
  }
  
  @media only screen and ( max-width: 600px) {

    .getWaiter{
      width: 90%;
      background: #08AA3E !important;
      color: #FFFFFF !important;
      margin-top: 5rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 4rem !important;
      position: sticky !important;
      bottom: 4rem !important;   
    }
  }
  @media only screen and (max-width: 360px) {

    .getWaiter{
        width: 90%;
        background: #08AA3E !important;
        color: #FFFFFF !important;
        margin-top: 10rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4rem !important;
        position: sticky !important;
        bottom: 4rem !important;   
    }
  }